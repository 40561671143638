import React from "react";
import * as styles from "./other-link.module.scss";
import {useHover} from "../../../../hooks/useHover";
import {displayImage} from "../../../../global/functions/functions";

type ReactProps = {
    data: any
}

const OtherLink: React.FC<ReactProps> = ({data}) => {

    const [hoverRef, isHovered] = useHover<HTMLDivElement>();

    return (
        <li
            ref={hoverRef}
            className={styles.linkBox}
            style={{ backgroundColor: isHovered ? data.mainColor.hex : "rgba(244, 244, 244, 255)" }}
        >
            <a title={data.titleAttribute || " "} aria-label={`Link to ${data.title}`} className={styles.link} href={data.slug[0] === '/' ? data.slug : `/${data.slug}`}>
                <div className={styles.linkBackgroundLogo}>
                    {displayImage(isHovered ? data.backgroundIconHover : data.backgroundIcon, styles.bgLogo)}
                </div>
                <div className={styles.linkIcon}>
                    {displayImage(isHovered ? data.iconHover : data.icon, styles.icon)}
                </div>
                <p
                    className={styles.linkTitle}
                    style={{color: data.mainColor.hex}}
                >{data.title}</p>
            </a>
        </li>
    )

}

export default OtherLink;