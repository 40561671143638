import React from "react";
import * as styles from "./links-group-side.module.scss";
import OtherLink from "./link/other-link";

type RenderProps = {
    data: any
}

const LinksGroupSide:React.FC<RenderProps> = ({data}) => {

    return (
        <ul className={styles.sideColumn}>
            {data && data.map(item => {
                return <OtherLink key={item.id} data={item} />
            })}
        </ul>
    )

}

export default LinksGroupSide;