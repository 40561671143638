import React from "react"
import { Marker, MarkerClusterer } from "@react-google-maps/api"
import { ClustererOptions, ClusterIconStyle } from "@react-google-maps/marker-clusterer"
import SelectedMarker from "../../assets/images/subsidiary-selected.png"
import UnselectedMarker from "../../assets/images/subsidiary.png"
import useWindowWidth from "../../hooks/use-window-width"

type RenderProps = {
  transportNetworks: any
  selectedNetwork: any
  onClick: (element) => void
  urlIconMarkerClusterer: string
  displayCluster: boolean
}

const MarkersFiltered: React.FC<RenderProps> = ({
  transportNetworks,
  selectedNetwork,
  onClick,
  urlIconMarkerClusterer,
  displayCluster
}) => {
  const isMobile = useWindowWidth() <= 800;
  const isSelectedButton = element => {
    return (
      selectedNetwork &&
      Object.keys(selectedNetwork).length !== 0 &&
      element && element.location &&  element?.location.longitude == selectedNetwork.location.longitude && element?.location.latitude == selectedNetwork.location.latitude
    )
  }
  const options: ClustererOptions = {
    imagePath: "https://developers.google.com/maps/documentation/javascript/examples/markerclusterer/m",
  }
  const clusterStyle: ClusterIconStyle = {
    url: urlIconMarkerClusterer,
    height: 68,
    width: 68,
    fontFamily: "Raleway Regular, sans-serif",
    fontStyle: "normal",
    textColor: "white",
    textSize: 18,
  }

  
  return (
   displayCluster ? <MarkerClusterer
      options={options}
      styles={[
        {
          ...clusterStyle,
          height: 52,
          width: 52,
          textSize: 16,
        },
        clusterStyle,
        clusterStyle,
        clusterStyle,
        clusterStyle,
      ]}
    >
      {clusterer =>
        (transportNetworks.busLocationList as any)?.map((element, indexElement) => (
          <Marker
            title={element.title}
            position={{
              lat: element && element.location && element.location.latitude,
              lng: element && element.location && element.location.longitude,
            }}
            icon={{
              url: isSelectedButton(element) ? SelectedMarker : UnselectedMarker,
              scaledSize: {
                width: isMobile ? 30 : isSelectedButton(element) ? 63 : 36,
                height: isMobile ? 51 : isSelectedButton(element) ? 113 : 61,
                equals: null,
              },
            }}
            onClick={() => onClick(element)}
            clusterer={clusterer}
            key={indexElement}
          />
        ))
      }
    </MarkerClusterer>
    : <>
    {
      (transportNetworks.busLocationList as any)?.map((element, indexElement) =>(
        <Marker
            title={element.title}
            position={{
              lat: element && element.location && element.location.latitude,
              lng: element && element.location && element.location.longitude,
            }}
            icon={{
              url: isSelectedButton(element) ? SelectedMarker : UnselectedMarker,
              scaledSize: {
                width: isMobile ? 30 : isSelectedButton(element) ? 63 : 36,
                height: isMobile ? 51 : isSelectedButton(element) ? 113 : 61,
                equals: null,
              },
            }}
            onClick={() => onClick(element)}
            key={indexElement}
          />
      ))
    }
     </>
  )
}

export default MarkersFiltered
