export const MAP_CUSTOM_STYLE = [
    {
      featureType: "all",
      elementType: "geometry",
      stylers: [{ color: "#faeee1" }],
    },
    {
      featureType: "all",
      elementType: "labels.text",
      stylers: [{ visibility: "off" }],
    },
    {
      featureType: "administrative",
      elementType: "geometry.stroke",
      stylers: [{ color: "#005f7d" }],
    },
    {
      featureType: "poi.park",
      stylers: [{ color: "#E2F0B2" }],
    },
    {
      "featureType": "landscape.natural.terrain",
      "elementType": "all",
      "stylers": [
        {
          "color": "#3dff40"
        }
      ]
    },
    {
      featureType: "administrative.province",
      elementType: "all",
      stylers: [{ visibility: "off" }],
    },
    {
      featureType: "poi",
      elementType: "labels.icon",
      stylers: [{ visibility: "off" }],
    },
    {
      featureType: "road",
      elementType: "labels",
      stylers: [{ visibility: "off" }],
    },
    {
      featureType: "transit",
      elementType: "all",
      stylers: [{ visibility: "off" }],
    },
    {
      "featureType": "road.arterial",
      "elementType": "geometry",
      "stylers": [
        { "color": "#CCFFFF" }
      ]
    },
    // {
    //   featureType: "park",
    //   elementType: "geometry.fill",
    //   stylers: [{color : "#e2f0d2"}]
    // },
    {
      featureType: "water",
      stylers: [          {
        "saturation": "100"
    },
    {
        "lightness": "-13"
    },
    {
        "weight": "0.01"
    },
    {
        "color": "#c2e6f5"
    }],
    },
    {
      featureType: "water",
      elementType: "labels",
      stylers: [{ visibility: "on" }, {
        "color": "#c2e6f5"
      }],
    },
  ]
  
  export const GOOGLE_MAP_API_PARAMETERS = {
    id: "1614a4c45a6895bf",
    googleMapsApiKey: "AIzaSyC1WSMijD7FzHj8q8PIY13tYhNdncjQ_kQ",
  }
  
  export const DEFAULT_MAP_PARAMETERS = {
    latitude: 47.8588897,
    longitude: 2.320041,
    zoom: 4.9,
    defaultZoom: 5.5,
    mobileZoom: 5,
    cartographyZoom: 2.75,
  }
  
  export const MAP_CONTAINER_STYLE = {
    width: "100%",
    height: "100%",
  }
  
  export const POLYGON_COLOR = "#00AAC3"
  export const POLYGON_SELECTED_COLOR = "#124162"
  
  export const PAGE_NOT_FOUND_ELEMENTS = {
    title: "Oups !",
    breadcrumbHomeLabel: "Accueil",
    secondaryMessage: {
      text: "La page que vous recherchez semble introuvable.",
      errorCode: "Code d'erreur: 404",
    },
    bouttonText: "Retour accueil",
  }
  